import routerOptions0 from "/home/runner/work/ott-web-portal2/ott-web-portal2/node_modules/.pnpm/nuxt@3.15.0_lcd75eziy5jkwwr5htxc2j2vh4/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/ott-web-portal2/ott-web-portal2/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}