export const THEME_COLOR = {
  DARK: "dark",
  LIGHT: "light",
} as const;

export const DEVICE = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
} as const;

export const SOCIAL_PROVIDER = {
  FACEBOOK: "facebook",
  APPLE: "apple",
  GOOGLE: "google",
  IFA: "ifa",
} as const;

export const AUTH_METHOD = {
  SIGN_UP: "signup",
  SIGN_IN: "signin",
} as const;

export const SOURCE_LOCATION = {
  CATEGORY: "category",
  TEAM: "team",
  LEAGUE: "league",
  HOME: "home",
  SUB_CATEGORY: "subcategory",
} as const;

export const SHARE_SOCIAL_LINK = {
  FACEBOOK: "facebook",
  X: "x",
  INSTAGRAM: "instagram",
  YOUTUBE: "youtube",
  TIKTOK: "tiktok",
  WEB: "web",
  WHATSAPP: "whatsapp",
} as const;
