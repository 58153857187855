<script setup lang="ts">
export interface BaseAlertProps {
  text?: string | null;
  dismissible?: boolean;
  type: "success" | "danger";
}
const props = withDefaults(defineProps<BaseAlertProps>(), {});
</script>

<template>
  <div class="baseAlert text-caption-bold" :class="[`baseAlert__${props.type}`]">
    <div class="flex items-center gap-3">
      <IconWarning v-if="props.type === 'danger'" class="inline-block" />
      <IconCheck v-else-if="props.type === 'success'" class="inline-block" fill="#53B25C" />

      <span>{{ props.text }}</span>
    </div>

    <IconClose v-if="props.dismissible" fill="currentColor" />
  </div>
</template>

<style lang="scss">
.baseAlert {
  @apply flex items-center justify-between gap-3 p-4;

  border-radius: var(--base-border-radius);

  &.baseAlert__danger {
    color: var(--base-error-color);
    border: solid 1px var(--base-error-border-color);
    background-color: var(--base-error-bgColor);
  }
  &.baseAlert__success {
    color: var(--base-success-color);
    border: solid 1px var(--base-success-border-color);
    background-color: var(--base-success-bgColor);
  }
}
</style>
