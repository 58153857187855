import { COMMERCE_TYPE } from "~/constants";

export const formatIdentityItems = (item: IResponseFollowedCategoryItemMedias): IModelCommerceIdentityItems => {
  return {
    id: item._id,
    title: item.title,
  };
};

export const formatSubscriptionItem = (item: IResponseSubscription): IModelCommerce => {
  const endDate = item.type === COMMERCE_TYPE.SVOD || item.type === COMMERCE_TYPE.CVOD
    ? item.contractEndDate || 0
    : item.offerEndDate;

  return {
    id: item._id,
    title: item.title,
    description: item.description,
    eventId: item.event_id || null,
    price: item.price,
    currency: item.currency || "N/A",
    type: item.type,
    isCanceled: Boolean(item.canceled),
    isActive: Boolean(item.active),
    startDate: item.offerStartDate,
    endDate,
    purchaseProcessing: item.purchaseProcessing,
    lastPayment: item.updated,
    autoRenew: item.renewable,
    duration: item.duration || null,
    durationUnits: item.durationUnits || "",
    allowedAccessIdentityItems: item.allowedAccessIdentityItems ? item.allowedAccessIdentityItems.map(formatIdentityItems) : [],
  };
};

export const formatCustomerSubscriptionItem = (item: IResponseCustomerSubscription): IModelCommerce => {
  return {
    id: item._id,
    title: item.title,
    description: item.description,
    productId: item.originalProductId,
    isCanceled: Boolean(item.canceled),
    price: item.price,
    currency: item.currency || "N/A",
    type: item.type,
    eventId: null,
    isActive: item.active,
    startDate: item.startDate,
    endDate: item.endDate,
    lastPayment: item.updated,
    autoRenew: item.autoRenew,
    duration: item.originalProduct?.duration,
    durationUnits: item.originalProduct?.durationUnits || "",
    allowedAccessIdentityItems: item.items ? item.items.map(formatIdentityItems) : [],
  };
};
