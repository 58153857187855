export const SEGMENT_TRACK_EVENTS = {
  SIGN_UP_STARTED: "Sign Up Started",
  SIGN_UP_COMPLETED: "Sign Up Completed",
  USER_SUCCESSFUL_LOGIN: "User Successful Login",
  USER_FAILED_LOGIN: "User Failed Login",
  HIGHLIGHT_PAGE_VISITED: "Highlight Page Visited",
  VISIT_FOLLOWING_PAGE: "Visit Following Page",
  TEAM_FOLLOWED: "Team Followed",
  TEAM_UNFOLLOWED: "Team Unfollowed",
  CATEGORY_FOLLOWED: "Category Followed",
  CATEGORY_UNFOLLOWED: "Category Unfollowed",
  WATCH_FULL_EVENT_CLICKED: "Watch Full Event Clicked",
  CLIPPING_PROCESS_STARTED: "Clipping Process Started",
  CLIP_CREATED: "Clip Created",
  KYC_SKIPPED: "KYC Skipped",
  CLICK_ON_SOCIAL_MEDIA_LINK: "Click On Social Media Link",
  CLICK_ON_BANNERS: "Click On Banners",
  CLICKS_ON_LEAGUES: "Clicks On Leagues (leagues platforms)",
  CLICKS_ON_SUB_CATEGORIES: "Clicks on sub categories",
  EVENT_SHARE_TO_SOCIAL: "Event Share To Social",
  CLIP_SHARE_TO_SOCIAL: "Clip Share To Social",
  CLICKS_ON_ROUNDS: "Clicks on rounds (leagues platforms)",
  USER_REQUEST_RESET_PASSWORD: "User request reset password",
  DOWNLOAD_CLIP_STARTED: "Download Clip Started",
  BACK_TO_EVENT_FROM_HIGHLIGHT: "Back to event from highlight",
  VISIT_MY_CLIPS_PAGE: "Visit my clips page",
  // OLD EVENTS, we will remove them after we confirm that they are not used anymore
  DOWNLOAD_VIDEO_STARTED: "Download Video Started",
  USER_USED_ACCESS_CODE: "User Used Access Code",
  SUBSCRIPTION_PURCHASE: "Subscription Purchase",
  DELETE_DEVICE: "Delete Device",
  SUBSCRIPTION_CANCELED: "Subscription canceled",
  USER_UPDATED_PROFILE: "User Update Profile",
} as const;
