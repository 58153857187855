import { EVENT_STATUS } from "~/constants";

export const getTagMetadata = (item: IResponseTag, event: IModelEvent) => {
  const color = item.playerColor;
  const teamId = item.teamId;
  const teamTitle = (item.teamId === event.team1Id)
    ? event.team1Name
    : (item.teamId === event.team2Id)
        ? event.team2Name
        : undefined;
  const teamLogo = (item.teamId === event.team1Id)
    ? event.team1LogoUrl
    : (item.teamId === event.team2Id)
        ? event.team2LogoUrl
        : undefined;
  return {
    color,
    title: teamTitle,
    teamId,
    logo: teamLogo,
  };
};

export const formatTag = (item: IResponseTag, event: IModelEvent): IModelTag => {
  const startTime = item.startTime ? item.startTime / 1000 : item.timePTS / 1000;
  const endTime = item.endTime ? item.endTime / 1000 : item.timePTS / 1000;
  const { color, title, teamId, logo } = getTagMetadata(item, event);

  return {
    title: item.title || "NO TITLE",
    id: item._id,
    startTime,
    endTime, // TODO:  should consider about it
    type: item.type,
    global: item.global,
    playerNumber: item.playerNumber,
    tagTeam: {
      color,
      teamId,
      title,
      logo,
    },
  };
};

export const formatTagsCreateOption = (options: IModelTag, event: IModelEvent): IRequestTagsCreateOptions => {
  const videoType = event.eventStatus === EVENT_STATUS.LIVE ? EVENT_STATUS.LIVE : "vod";
  return {
    targetId: event.referenceId,
    title: options.title,
    startTime: options.startTime * 1000,
    endTime: options.endTime * 1000,
    type: options.type,
    global: options.global,
    mode: "sync",
    playerColor: options.tagTeam?.color,
    playerNumber: options.playerNumber || undefined,
    teamId: options.tagTeam?.teamId || undefined,
    videoType,
    streamName: "hd",
  };
};

export const formatTagsUpdateOptions = (options: IModelTag): IRequestTagsUpdateOptions => {
  return {
    id: options.id,
    title: options.title,
    type: options.type,
    global: options.global,
    playerColor: options.tagTeam?.color,
    playerNumber: options.playerNumber || "",
    teamId: options.tagTeam?.teamId,
  };
};
