import AuthAPI from "~/services/auth";

const useUserStore = defineStore("user", {
  state: () => ({
    user: null as null | IModelUser,
    devices: [] as IModelDevice[],
    followed: {} as Record<IModelFollowedItem["id"], IModelFollowedItem>,
    selected: {} as Record<string, boolean>,
    token: null as null | string, // TODO: Handling the users who are currently logged in. Will be removed in the future.
    userTokenData: null as null | IModelUserTokenData,
    creditCard: null as null | IModelCreditCard,
    creditCardToken: null as null | string,
    ppvTickets: [] as IModelCommerce[],
    pendingAccessTokenRefresh: null as null | Promise<void>,
  }),

  // TODO: Manage data storing
  // Move everything from being stored into local storage to session storage
  // except token & user.
  persist: {
    key: "ott-data",
    paths: ["user", "token", "userTokenData", "followed", "devices"],
  },

  getters: {
    isAuthenticated: state => Boolean(state.userTokenData?.token && state.user),

    followedIdentitiesIds: (state) => {
      return Object.values(state.followed).reduce((acc: string[], item) => {
        if (item.identitiesId && item.identitiesId.length > 0) {
          item.identitiesId.forEach((id) => {
            if (!acc.includes(id))
              acc.push(id);
          });
        }
        return acc;
      }, []);
    },

    followedItems: (state) => {
      return Object.values(state.followed);
    },
  },

  actions: {
    logout(options: { revokeToken: boolean } = { revokeToken: true }): void {
      const segment = useSegment();

      if (options.revokeToken)
        this.userTokenData?.token && AuthAPI.logout(this.userTokenData.token);

      this.token = null;
      this.userTokenData = null;
      this.user = null;
      this.creditCard = null;
      this.creditCardToken = null;
      this.selected = {};
      this.followed = {};
      this.devices = [];
      this.ppvTickets = [];

      segment.reset();
    },
    refreshAccessToken(): Promise<void> {
      return AuthAPI.refreshAccessToken();
    },
  },
});

export default useUserStore;

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
