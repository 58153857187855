<script setup lang="ts">
/* eslint-disable max-len */
import { FALLBACK, THEME_COLOR } from "~/constants";

export interface ImageFallbackProps {
  variant?: (typeof FALLBACK)[keyof typeof FALLBACK];
}

const props = withDefaults(defineProps<ImageFallbackProps>(), {
  variant: FALLBACK.IMAGE,
});
const color = useColorMode();
const isDarkTheme = computed(() => color.value === THEME_COLOR.DARK);
</script>

<template>
  <div>
    <template v-if="props.variant === FALLBACK.HORIZONTAL_BANNER">
      <template v-if="isDarkTheme">
        <svg viewBox="0 0 2000 475" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>banner stub</title>
          <g id="banner-stub" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect fill="#171F2E" x="0" y="0" width="100%" height="100%" />
            <g id="illustration" transform="translate(821, 162)">
              <path id="Path" d="M248.124745,34.5409006 L257.837117,35.5263158 L258.924018,24.9675972 C259.737566,17.0715392 253.924647,9.98756968 245.969539,9.18046508 L233.29771,7.89473684 L248.124745,34.5409006 Z" fill="#1C2535" fill-rule="nonzero" />
              <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="235.274809 33.5526316 221.041671 8.06706909 199.687023 5.92105263 213.920161 31.406571" />
              <path id="Path" d="M170.030534,27.6315789 L155.534856,2.435284 L150.803271,1.97368421 C146.938303,8.55263158 145.005818,11.8421053 145.005818,11.8421053 C145.005818,11.8421053 146.098026,16.3980296 148.282443,25.5098781 L170.030534,27.6315789 Z" fill="#1C2535" fill-rule="nonzero" />
              <path id="Path" d="M147.317459,35.5263158 L138.396947,46.6409864 L145.005818,62.752511 L132.759354,85.059308 L140.725691,105.451437 L129.5,124.342105 L136.419847,140.206194 L232.263542,149.926986 C239.967414,150.708759 246.878029,145.122878 247.665884,137.478476 L257.022901,46.6409864 L147.317459,35.5263158 Z" fill="#2C374B" fill-rule="nonzero" />
              <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="203.641221 29.6052632 189.35522 4.11899801 168.053435 1.97368421 182.251639 27.4509805" />
              <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="96.8778626 26.912986 76.9778986 3.94736842 55.3587786 6.63961766 75.2589249 29.6052632" />
              <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="63.2671756 30.8603544 43.367073 7.89473684 21.7480916 10.5869581 41.648103 33.5526316" />
              <path id="Path" d="M29.6564885,34.0931113 L10.1848827,11.8421053 C3.62828234,13.6604228 -0.740898525,20.1581966 0.104455213,27.2032673 L1.33996739,37.5 L29.6456386,34.0028505 L29.6564885,34.0931113 Z" fill="#1C2535" fill-rule="nonzero" />
              <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="106.763359 23.6842105 100.512738 9.82954097 103.80665 0 86.9923664 1.99642802" />
              <path id="Path" d="M1.97709924,46.4809359 L13.0473689,137.671643 C13.9788811,145.345265 20.9645947,150.831046 28.6153796,149.896225 L120.603053,138.663092 L110.484239,124.342105 L117.996425,105.04965 L105.002551,85.059308 L112.969432,63.6826682 L100.512738,46.4809359 L107.842933,33.5526316 L1.97709924,46.4809359 Z" fill="#2C374B" fill-rule="nonzero" />
              <path id="Shape" d="M104.828364,52.5183265 L112.969432,63.6826682 L104.828364,84.4199711 L117.996425,105.04965 L111.69753,121.137914 L86.2708835,124.268999 C83.6437946,124.588028 80.9995044,123.854619 78.9198402,122.230961 C76.840176,120.607303 75.4954654,118.226176 75.1817646,115.610679 L69.2688861,66.3184057 C68.9550966,63.7030868 69.6982962,61.0686975 71.334275,58.9945682 C72.9703426,56.920528 75.3655695,55.5768665 77.9929245,55.2595314 L104.828364,52.5183265 Z M117.551556,105.992194 L94.3394131,96.2052055 C93.8251495,95.947666 93.2381797,95.8754836 92.6760364,96.0002432 C92.1147798,96.1250029 91.6129295,96.4386843 91.2564916,96.8896014 L79.5464438,110.081153 L80.1361623,115.010051 C80.292924,116.317354 80.9651906,117.507917 82.0049784,118.319746 C83.0448548,119.131575 84.3668669,119.498725 85.6801897,119.340102 L113.635241,116.048918 L117.551556,105.992194 Z M92.1449263,68.5485737 C90.1756514,68.7872211 88.3792755,69.7950118 87.1531115,71.3505865 C85.9263269,72.9062503 85.3695036,74.8818195 85.6047348,76.8424175 C85.8399661,78.8034611 86.8479227,80.5893956 88.4076487,81.8074954 C89.9663993,83.0255062 91.9489741,83.5754289 93.9191356,83.3367815 C95.8892972,83.098045 97.6847864,82.0902543 98.9119257,80.5346796 C100.138178,78.9790158 100.695002,77.0034466 100.460036,75.0423139 C100.225071,73.0817158 99.2160506,71.2958705 97.6572999,70.0777706 C96.0976626,68.8597599 94.1150878,68.3098372 92.1449263,68.5485737 Z" fill="#6E7C96" />
              <path id="Shape" d="M172.747056,53.9254461 C175.427248,54.1952699 177.891105,55.5131995 179.596435,57.5887393 C181.301765,59.6647323 182.110077,62.3286982 181.843053,64.9948402 L176.799477,115.255686 C176.532454,117.922191 175.210914,120.372002 173.12632,122.065654 C171.042631,123.760213 168.366965,124.560799 165.686774,124.290613 L131.586231,120.773302 L134.230046,116.150421 L138.139626,98.3145014 L132.759354,85.059308 L145.005818,62.752511 L140.940335,52.5183265 L172.747056,53.9254461 Z M155.417683,85.4505843 C154.967816,85.1672512 154.4392,85.0317046 153.906964,85.0639819 C153.375632,85.0957152 152.86693,85.2934591 152.45508,85.6292881 L138.141099,98.3145395 L134.220736,116.194557 L166.191855,119.264963 C167.532404,119.400963 168.872047,119.001124 169.914797,118.153391 C170.958451,117.305658 171.619221,116.079846 171.75228,114.745233 L173.518256,97.1541005 L155.417683,85.4505843 Z" fill="#6E7C96" />
            </g>
          </g>
        </svg>
      </template>
      <template v-else>
        <svg viewBox="0 0 2000 475" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>banner stub_light</title>
          <g id="banner-stub_light" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect fill="#F1F2F2" x="0" y="0" width="100%" height="100%" />
            <g id="illustration" transform="translate(821, 163)">
              <path id="Path" d="M248.124745,34.5409006 L257.837117,35.5263158 L258.924018,24.9675972 C259.737566,17.0715392 253.924647,9.98756968 245.969539,9.18046508 L233.29771,7.89473684 L248.124745,34.5409006 Z" fill="#CACACA" fill-rule="nonzero" />
              <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="235.274809 33.5526316 221.041671 8.06706909 199.687023 5.92105263 213.920161 31.406571" />
              <path id="Path" d="M170.030534,27.6315789 L155.534856,2.435284 L150.803271,1.97368421 C146.938303,8.55263158 145.005818,11.8421053 145.005818,11.8421053 C145.005818,11.8421053 146.098026,16.3980296 148.282443,25.5098781 L170.030534,27.6315789 Z" fill="#CACACA" fill-rule="nonzero" />
              <path id="Path" d="M147.317459,35.5263158 L138.396947,46.6409864 L145.005818,62.752511 L132.759354,85.059308 L140.725691,105.451437 L129.5,124.342105 L136.419847,140.206194 L232.263542,149.926986 C239.967414,150.708759 246.878029,145.122878 247.665884,137.478476 L257.022901,46.6409864 L147.317459,35.5263158 Z" fill="#E9E9E9" fill-rule="nonzero" />
              <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="203.641221 29.6052632 189.35522 4.11899801 168.053435 1.97368421 182.251639 27.4509805" />
              <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="96.8778626 26.912986 76.9778986 3.94736842 55.3587786 6.63961766 75.2589249 29.6052632" />
              <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="63.2671756 30.8603544 43.367073 7.89473684 21.7480916 10.5869581 41.648103 33.5526316" />
              <path id="Path" d="M29.6564885,34.0931113 L10.1848827,11.8421053 C3.62828234,13.6604228 -0.740898525,20.1581966 0.104455213,27.2032673 L1.33996739,37.5 L29.6456386,34.0028505 L29.6564885,34.0931113 Z" fill="#CACACA" fill-rule="nonzero" />
              <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="106.763359 23.6842105 100.512738 9.82954097 103.80665 0 86.9923664 1.99642802" />
              <path id="Path" d="M1.97709924,46.4809359 L13.0473689,137.671643 C13.9788811,145.345265 20.9645947,150.831046 28.6153796,149.896225 L120.603053,138.663092 L110.484239,124.342105 L117.996425,105.04965 L105.002551,85.059308 L112.969432,63.6826682 L100.512738,46.4809359 L107.842933,33.5526316 L1.97709924,46.4809359 Z" fill="#E9E9E9" fill-rule="nonzero" />
              <path id="Shape" d="M104.828364,52.5183265 L112.969432,63.6826682 L104.828364,84.4199711 L117.996425,105.04965 L111.69753,121.137914 L86.2708835,124.268999 C83.6437946,124.588028 80.9995044,123.854619 78.9198402,122.230961 C76.840176,120.607303 75.4954654,118.226176 75.1817646,115.610679 L69.2688861,66.3184057 C68.9550966,63.7030868 69.6982962,61.0686975 71.334275,58.9945682 C72.9703426,56.920528 75.3655695,55.5768665 77.9929245,55.2595314 L104.828364,52.5183265 Z M117.551556,105.992194 L94.3394131,96.2052055 C93.8251495,95.947666 93.2381797,95.8754836 92.6760364,96.0002432 C92.1147798,96.1250029 91.6129295,96.4386843 91.2564916,96.8896014 L79.5464438,110.081153 L80.1361623,115.010051 C80.292924,116.317354 80.9651906,117.507917 82.0049784,118.319746 C83.0448548,119.131575 84.3668669,119.498725 85.6801897,119.340102 L113.635241,116.048918 L117.551556,105.992194 Z M92.1449263,68.5485737 C90.1756514,68.7872211 88.3792755,69.7950118 87.1531115,71.3505865 C85.9263269,72.9062503 85.3695036,74.8818195 85.6047348,76.8424175 C85.8399661,78.8034611 86.8479227,80.5893956 88.4076487,81.8074954 C89.9663993,83.0255062 91.9489741,83.5754289 93.9191356,83.3367815 C95.8892972,83.098045 97.6847864,82.0902543 98.9119257,80.5346796 C100.138178,78.9790158 100.695002,77.0034466 100.460036,75.0423139 C100.225071,73.0817158 99.2160506,71.2958705 97.6572999,70.0777706 C96.0976626,68.8597599 94.1150878,68.3098372 92.1449263,68.5485737 Z" fill="#CACACA" />
              <path id="Shape" d="M172.747056,53.9254461 C175.427248,54.1952699 177.891105,55.5131995 179.596435,57.5887393 C181.301765,59.6647323 182.110077,62.3286982 181.843053,64.9948402 L176.799477,115.255686 C176.532454,117.922191 175.210914,120.372002 173.12632,122.065654 C171.042631,123.760213 168.366965,124.560799 165.686774,124.290613 L131.586231,120.773302 L134.230046,116.150421 L138.139626,98.3145014 L132.759354,85.059308 L145.005818,62.752511 L140.940335,52.5183265 L172.747056,53.9254461 Z M155.417683,85.4505843 C154.967816,85.1672512 154.4392,85.0317046 153.906964,85.0639819 C153.375632,85.0957152 152.86693,85.2934591 152.45508,85.6292881 L138.141099,98.3145395 L134.220736,116.194557 L166.191855,119.264963 C167.532404,119.400963 168.872047,119.001124 169.914797,118.153391 C170.958451,117.305658 171.619221,116.079846 171.75228,114.745233 L173.518256,97.1541005 L155.417683,85.4505843 Z" fill="#CACACA" />
            </g>
          </g>
        </svg>
      </template>
    </template>
    <template v-else-if="props.variant === FALLBACK.VERTICAL_BANNER">
      <template v-if="isDarkTheme">
        <svg viewBox="0 0 588 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Slider_sign in_dark@1x</title>
          <g id="Other" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="image">
              <rect id="Rectangle" fill="#171F2E" x="0" y="0" width="588" height="1024" />
              <g id="illustration" transform="translate(191, 452)">
                <path id="Path" d="M198.308194,27.6327205 L206.070592,28.4210526 L206.939273,19.9740777 C207.589483,13.6572314 202.943637,7.99005574 196.585693,7.34437206 L186.458015,6.31578947 L198.308194,27.6327205 Z" fill="#1C2535" fill-rule="nonzero" />
                <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="188.038168 26.8421053 176.662648 6.45365527 159.59542 4.73684211 170.97094 25.1252568" />
                <path id="Path" d="M135.89313,22.1052632 L124.30778,1.9482272 L120.526167,1.57894737 C117.437176,6.84210526 115.892681,9.47368421 115.892681,9.47368421 C115.892681,9.47368421 116.765604,13.1184236 118.51145,20.4079025 L135.89313,22.1052632 Z" fill="#1C2535" fill-rule="nonzero" />
                <path id="Path" d="M117.740208,28.4210526 L110.610687,37.3127891 L115.892681,50.2020088 L106.104967,68.0474464 L112.471885,84.3611494 L103.5,99.4736842 L109.030534,112.164955 L185.63148,119.941588 C191.788628,120.567007 197.311784,116.098302 197.941459,109.982781 L205.419847,37.3127891 L117.740208,28.4210526 Z" fill="#2C374B" fill-rule="nonzero" />
                <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="162.755725 23.6842105 151.337956 3.29519841 134.312977 1.57894737 145.660576 21.9607844" />
                <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="77.4274809 21.5303888 61.5228765 3.15789474 44.2442748 5.31169413 60.1490249 23.6842105" />
                <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="50.5648855 24.6882835 34.6601703 6.31578947 17.3816794 8.4695665 33.2863217 26.8421053" />
                <path id="Path" d="M23.7022901,27.274489 L8.14004138,9.47368421 C2.89982411,10.9283383 -0.592146698,16.1265573 0.0834835097,21.7626138 L1.07093918,30 L23.6936185,27.2022804 L23.7022901,27.274489 Z" fill="#1C2535" fill-rule="nonzero" />
                <polygon id="Path" fill="#1C2535" fill-rule="nonzero" points="85.3282443 18.9473684 80.3325744 7.86363277 82.9651602 0 69.5267176 1.59714241" />
                <path id="Path" d="M1.58015267,37.1847487 L10.42782,110.137314 C11.1723103,116.276212 16.7554869,120.664837 22.8702069,119.91698 L96.389313,110.930474 L88.3020749,99.4736842 L94.3060228,84.0397201 L83.9209576,68.0474464 L90.2883106,50.9461345 L80.3325744,37.1847487 L86.1910696,26.8421053 L1.58015267,37.1847487 Z" fill="#2C374B" fill-rule="nonzero" />
                <path id="Shape" d="M83.7817425,42.0146612 L90.2883106,50.9461345 L83.7817425,67.5359769 L94.3060228,84.0397201 L89.2717712,96.9103315 L68.9500884,99.4151996 C66.8504459,99.6704222 64.7370557,99.0836953 63.0749302,97.7847691 C61.4128048,96.4858428 60.3380747,94.580941 60.0873563,92.4885433 L55.3616194,53.0547245 C55.1108301,50.9624694 55.7048159,48.854958 57.0123357,47.1956546 C58.3199263,45.5364224 60.2342583,44.4614932 62.3341134,44.2076251 L83.7817425,42.0146612 Z M93.9504717,84.7937555 L75.3986815,76.9641644 C74.9876678,76.7581328 74.5185452,76.7003869 74.0692646,76.8001946 C73.6206927,76.9000023 73.2196,77.1509475 72.934725,77.5116811 L63.5757292,88.0649223 L64.0470486,92.0080404 C64.1723369,93.0538828 64.7096311,94.0063337 65.5406584,94.6557968 C66.3717566,95.30526 67.4283454,95.5989798 68.4779895,95.4720814 L90.8204434,92.8391342 L93.9504717,84.7937555 Z M73.6447866,54.8388589 C72.0708874,55.0297769 70.6351739,55.8360094 69.6551895,57.0804692 C68.6747091,58.3250002 68.2296805,59.9054556 68.4176838,61.473934 C68.6056872,63.0427689 69.4112741,64.4715165 70.6578505,65.4459964 C71.9036474,66.4204049 73.4881762,66.8603431 75.0627841,66.6694252 C76.6373919,66.478436 78.0723969,65.6722034 79.0531607,64.4277437 C80.0332159,63.1832126 80.4782446,61.6027573 80.2904538,60.0338511 C80.1026631,58.4653727 79.2962257,57.0366964 78.0504289,56.0622165 C76.8039234,55.0878079 75.2193945,54.6478697 73.6447866,54.8388589 Z" fill="#6E7C96" />
                <path id="Shape" d="M138.064249,43.1403569 C140.206333,43.3562159 142.175517,44.4105596 143.538464,46.0709914 C144.901411,47.7317859 145.547436,49.8629586 145.334023,51.9958721 L141.303057,92.2045491 C141.089644,94.3377528 140.033433,96.297602 138.367368,97.6525236 C136.702026,99.0081705 134.563559,99.6486394 132.421475,99.4324902 L105.167374,96.618642 L107.280384,92.9203369 L110.405029,78.6516012 L106.104967,68.0474464 L115.892681,50.2020088 L112.643434,42.0146612 L138.064249,43.1403569 Z M124.214133,68.3604674 C123.854587,68.1338009 123.432102,68.0253637 123.006724,68.0511855 C122.582069,68.0765722 122.1755,68.2347672 121.846338,68.5034305 L110.406207,78.6516316 L107.272943,92.9556456 L132.825151,95.4119706 C133.896554,95.5207706 134.967235,95.2008988 135.800629,94.5227127 C136.634747,93.8445265 137.162852,92.8638766 137.269197,91.7961868 L138.680614,77.7232804 L124.214133,68.3604674 Z" fill="#6E7C96" />
              </g>
            </g>
          </g>
        </svg>
      </template>
      <template v-else>
        <svg viewBox="0 0 588 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Slider_sign in_light@1x</title>
          <g id="Other" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="image">
              <rect id="Rectangle" fill="#F1F2F2" x="0" y="0" width="588" height="1024" />
              <g id="illustration" transform="translate(191, 452)">
                <path id="Path" d="M198.308194,27.6327205 L206.070592,28.4210526 L206.939273,19.9740777 C207.589483,13.6572314 202.943637,7.99005574 196.585693,7.34437206 L186.458015,6.31578947 L198.308194,27.6327205 Z" fill="#CACACA" fill-rule="nonzero" />
                <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="188.038168 26.8421053 176.662648 6.45365527 159.59542 4.73684211 170.97094 25.1252568" />
                <path id="Path" d="M135.89313,22.1052632 L124.30778,1.9482272 L120.526167,1.57894737 C117.437176,6.84210526 115.892681,9.47368421 115.892681,9.47368421 C115.892681,9.47368421 116.765604,13.1184236 118.51145,20.4079025 L135.89313,22.1052632 Z" fill="#CACACA" fill-rule="nonzero" />
                <path id="Path" d="M117.740208,28.4210526 L110.610687,37.3127891 L115.892681,50.2020088 L106.104967,68.0474464 L112.471885,84.3611494 L103.5,99.4736842 L109.030534,112.164955 L185.63148,119.941588 C191.788628,120.567007 197.311784,116.098302 197.941459,109.982781 L205.419847,37.3127891 L117.740208,28.4210526 Z" fill="#E9E9E9" fill-rule="nonzero" />
                <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="162.755725 23.6842105 151.337956 3.29519841 134.312977 1.57894737 145.660576 21.9607844" />
                <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="77.4274809 21.5303888 61.5228765 3.15789474 44.2442748 5.31169413 60.1490249 23.6842105" />
                <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="50.5648855 24.6882835 34.6601703 6.31578947 17.3816794 8.4695665 33.2863217 26.8421053" />
                <path id="Path" d="M23.7022901,27.274489 L8.14004138,9.47368421 C2.89982411,10.9283383 -0.592146698,16.1265573 0.0834835097,21.7626138 L1.07093918,30 L23.6936185,27.2022804 L23.7022901,27.274489 Z" fill="#CACACA" fill-rule="nonzero" />
                <polygon id="Path" fill="#CACACA" fill-rule="nonzero" points="85.3282443 18.9473684 80.3325744 7.86363277 82.9651602 0 69.5267176 1.59714241" />
                <path id="Path" d="M1.58015267,37.1847487 L10.42782,110.137314 C11.1723103,116.276212 16.7554869,120.664837 22.8702069,119.91698 L96.389313,110.930474 L88.3020749,99.4736842 L94.3060228,84.0397201 L83.9209576,68.0474464 L90.2883106,50.9461345 L80.3325744,37.1847487 L86.1910696,26.8421053 L1.58015267,37.1847487 Z" fill="#E9E9E9" fill-rule="nonzero" />
                <path id="Shape" d="M83.7817425,42.0146612 L90.2883106,50.9461345 L83.7817425,67.5359769 L94.3060228,84.0397201 L89.2717712,96.9103315 L68.9500884,99.4151996 C66.8504459,99.6704222 64.7370557,99.0836953 63.0749302,97.7847691 C61.4128048,96.4858428 60.3380747,94.580941 60.0873563,92.4885433 L55.3616194,53.0547245 C55.1108301,50.9624694 55.7048159,48.854958 57.0123357,47.1956546 C58.3199263,45.5364224 60.2342583,44.4614932 62.3341134,44.2076251 L83.7817425,42.0146612 Z M93.9504717,84.7937555 L75.3986815,76.9641644 C74.9876678,76.7581328 74.5185452,76.7003869 74.0692646,76.8001946 C73.6206927,76.9000023 73.2196,77.1509475 72.934725,77.5116811 L63.5757292,88.0649223 L64.0470486,92.0080404 C64.1723369,93.0538828 64.7096311,94.0063337 65.5406584,94.6557968 C66.3717566,95.30526 67.4283454,95.5989798 68.4779895,95.4720814 L90.8204434,92.8391342 L93.9504717,84.7937555 Z M73.6447866,54.8388589 C72.0708874,55.0297769 70.6351739,55.8360094 69.6551895,57.0804692 C68.6747091,58.3250002 68.2296805,59.9054556 68.4176838,61.473934 C68.6056872,63.0427689 69.4112741,64.4715165 70.6578505,65.4459964 C71.9036474,66.4204049 73.4881762,66.8603431 75.0627841,66.6694252 C76.6373919,66.478436 78.0723969,65.6722034 79.0531607,64.4277437 C80.0332159,63.1832126 80.4782446,61.6027573 80.2904538,60.0338511 C80.1026631,58.4653727 79.2962257,57.0366964 78.0504289,56.0622165 C76.8039234,55.0878079 75.2193945,54.6478697 73.6447866,54.8388589 Z" fill="#CACACA" />
                <path id="Shape" d="M138.064249,43.1403569 C140.206333,43.3562159 142.175517,44.4105596 143.538464,46.0709914 C144.901411,47.7317859 145.547436,49.8629586 145.334023,51.9958721 L141.303057,92.2045491 C141.089644,94.3377528 140.033433,96.297602 138.367368,97.6525236 C136.702026,99.0081705 134.563559,99.6486394 132.421475,99.4324902 L105.167374,96.618642 L107.280384,92.9203369 L110.405029,78.6516012 L106.104967,68.0474464 L115.892681,50.2020088 L112.643434,42.0146612 L138.064249,43.1403569 Z M124.214133,68.3604674 C123.854587,68.1338009 123.432102,68.0253637 123.006724,68.0511855 C122.582069,68.0765722 122.1755,68.2347672 121.846338,68.5034305 L110.406207,78.6516316 L107.272943,92.9556456 L132.825151,95.4119706 C133.896554,95.5207706 134.967235,95.2008988 135.800629,94.5227127 C136.634747,93.8445265 137.162852,92.8638766 137.269197,91.7961868 L138.680614,77.7232804 L124.214133,68.3604674 Z" fill="#CACACA" />
              </g>
            </g>
          </g>
        </svg>
      </template>
    </template>
    <template v-else>
      <template v-if="isDarkTheme">
        <svg width="100%" height="100%" viewBox="0 0 298 168" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path fill="#171F2E" fill-rule="nonzero" d="M0 0h298v168H0z" />
            <path d="M224.99 73.458c-.016-2.484-1.827-4.552-4.128-4.62l-51.709-1.55c-3.127-.093-5.675 2.225-5.649 5.18l.014 1.637c.026 2.953 2.636 5.342 5.76 5.336l51.644-.101c2.3-.005 4.094-2.022 4.078-4.505l-.01-1.377zM215.01 112.265c-.016-2.579-2.019-4.5-4.467-4.288l-55.327 4.787c-3.363.291-6.128 3.033-6.1 6.117l.016 1.71c.028 3.083 2.823 5.254 6.182 4.854l55.302-6.56c2.446-.29 4.422-2.613 4.405-5.19l-.01-1.43zM169.498 89.262 87.93 91.51c-5.198.143-9.408 3.547-9.36 7.596l.027 2.341c.048 4.047 4.313 7.054 9.505 6.724l81.542-5.183c3.452-.22 6.185-3 6.16-6.214l-.017-1.86c-.027-3.214-2.835-5.746-6.29-5.65zM212.7 57.66c.012 1.788-1.36 3.166-3.08 3.076-1.74-.09-3.173-1.64-3.185-3.46-.013-1.822 1.399-3.197 3.138-3.074 1.722.123 3.114 1.67 3.127 3.459zM89.94 123.992c.027 2.404-2.397 4.624-5.45 4.964-3.096.344-5.66-1.37-5.688-3.832-.029-2.462 2.49-4.693 5.587-4.977 3.055-.281 5.524 1.442 5.551 3.845zM203.092 41.364c-1.493-.16-2.707.967-2.696 2.513.012 1.547 1.245 2.917 2.736 3.054 1.48.135 2.664-.994 2.654-2.517-.011-1.523-1.214-2.89-2.694-3.05zm.049 6.738c-2.123-.187-3.884-2.135-3.9-4.34-.016-2.205 1.718-3.806 3.842-3.57 2.099.233 3.8 2.175 3.816 4.333.015 2.157-1.661 3.762-3.758 3.577zM75.434 72.953l9.197.041-4.606-6.346-4.59 6.305zm11.49 1.112L73 74.048l7-9.568 6.924 9.585zM212.005 92.945l8.817-.88-3.86 8.181z" fill="#232F43" />
            <path d="M198.73 49.376c0-2.766-1.823-5.112-4.111-5.241l-90.53-5.125a5.66 5.66 0 0 0-.32-.01c-2.041-.012-3.802.956-4.887 2.481-.686.964-1.052 2.149-1.052 3.449v75.18c0 .433.011.85.096 1.25.021.1.028.198.054.295.105.388.24.758.419 1.102.76 1.464 2.143 2.498 3.825 2.817a6.095 6.095 0 0 0 1.874.068l70.978-8.447 19.543-2.34c2.288-.272 4.111-2.743 4.111-5.509v-59.97z" fill="#232F43" />
            <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v75.18c0 3.467 2.856 5.938 6.334 5.523l90.537-10.787c2.288-.273 4.124-2.734 4.124-5.5v-59.97c0-2.766-1.836-5.112-4.124-5.241z" fill="#374358" />
            <path d="M106.416 123.654c-2.359.272-4.295-1.415-4.295-3.761V45.02c0-2.347 1.936-4.154 4.295-4.03l90.536 4.76c1.558.082 2.817 1.683 2.817 3.568v60.168c0 1.885-1.26 3.565-2.817 3.745l-90.536 10.423z" fill="#2C374B" />
            <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v4.503l100.995 3.535v-3.592c0-2.766-1.836-5.112-4.124-5.241z" fill="#232F43" />
            <path d="M111.27 44.828c0 1.11-.9 1.971-2.016 1.926-1.12-.046-2.031-.99-2.031-2.11 0-1.118.911-1.98 2.031-1.924 1.115.055 2.015.999 2.015 2.108zM118.217 45.142c0 1.094-.875 1.944-1.959 1.9-1.088-.045-1.973-.975-1.973-2.078 0-1.102.885-1.952 1.973-1.899 1.084.054 1.96.984 1.96 2.077zM124.97 45.448c0 1.078-.851 1.917-1.905 1.874-1.057-.044-1.918-.96-1.918-2.047 0-1.087.86-1.926 1.918-1.874 1.054.052 1.904.968 1.904 2.047z" fill="#565F6F" />
            <path fill="#1C2535" d="m107.427 103.01 88.878-6.516V62.588l-88.878-.954z" />
            <path fill="#4B586F" d="m196.305 102.222-88.878 7.771v-3.157l88.878-7.201zM196.305 108.568l-88.878 9.169v-3.156l88.878-8.6zM158.099 56.1l-50.672-1.246v3.157l50.672.92zM191.825 56.928v2.616l4.48.081v-2.587zM145.102 93.885V67.642c0-1.684 1.313-2.79 2.443-2.067l19.545 12.514c1.181.757 1.181 3.112 0 3.944l-19.545 13.764c-1.13.796-2.443-.227-2.443-1.912z" />
          </g>
        </svg>
      </template>
      <template v-else>
        <svg width="100%" height="100%" viewBox="0 0 298 168" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path fill="#F1F2F2" fill-rule="nonzero" d="M0 0h298v168H0z" />
            <path d="M224.99 73.458c-.016-2.484-1.827-4.552-4.128-4.62l-51.709-1.55c-3.127-.093-5.675 2.225-5.649 5.18l.014 1.637c.026 2.953 2.636 5.342 5.76 5.336l51.644-.101c2.3-.005 4.094-2.022 4.078-4.505l-.01-1.377zM215.01 112.265c-.016-2.579-2.019-4.5-4.467-4.288l-55.327 4.787c-3.363.291-6.128 3.033-6.1 6.117l.016 1.71c.028 3.083 2.823 5.254 6.182 4.854l55.302-6.56c2.446-.29 4.422-2.613 4.405-5.19l-.01-1.43zM169.498 89.262 87.93 91.51c-5.198.143-9.408 3.547-9.36 7.596l.027 2.341c.048 4.047 4.313 7.054 9.505 6.724l81.542-5.183c3.452-.22 6.185-3 6.16-6.214l-.017-1.86c-.027-3.214-2.835-5.746-6.29-5.65zM212.7 57.66c.012 1.788-1.36 3.166-3.08 3.076-1.74-.09-3.173-1.64-3.185-3.46-.013-1.822 1.399-3.197 3.138-3.074 1.722.123 3.114 1.67 3.127 3.459zM89.94 123.992c.027 2.404-2.397 4.624-5.45 4.964-3.096.344-5.66-1.37-5.688-3.832-.029-2.462 2.49-4.693 5.587-4.977 3.055-.281 5.524 1.442 5.551 3.845zM203.092 41.364c-1.493-.16-2.707.967-2.696 2.514.012 1.546 1.245 2.916 2.736 3.053 1.48.135 2.664-.994 2.654-2.517-.011-1.523-1.214-2.89-2.694-3.05zm.049 6.738c-2.123-.187-3.884-2.135-3.9-4.34-.016-2.205 1.718-3.806 3.842-3.57 2.099.233 3.8 2.175 3.816 4.333.015 2.157-1.661 3.762-3.758 3.577zM75.434 72.953l9.197.041-4.606-6.346-4.59 6.305zm11.49 1.112L73 74.048l7-9.568 6.924 9.585zM212.005 92.945l8.817-.88-3.86 8.181z" fill="#DFDFDF" />
            <path d="M198.73 49.376c0-2.766-1.823-5.112-4.111-5.241l-90.53-5.125a5.66 5.66 0 0 0-.32-.01c-2.041-.012-3.802.956-4.887 2.481-.686.964-1.052 2.149-1.052 3.449v75.18c0 .433.011.85.096 1.25.021.1.028.198.054.295.105.388.24.758.419 1.102.76 1.464 2.143 2.498 3.825 2.817a6.095 6.095 0 0 0 1.874.068l70.978-8.447 19.543-2.34c2.288-.272 4.111-2.743 4.111-5.509v-59.97z" fill="#DFDFDF" />
            <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v75.18c0 3.467 2.856 5.938 6.334 5.523l90.537-10.787c2.288-.273 4.124-2.734 4.124-5.5v-59.97c0-2.766-1.836-5.112-4.124-5.241z" fill="#DFDFDF" />
            <path d="M106.416 123.654c-2.359.272-4.295-1.415-4.295-3.761V45.02c0-2.347 1.936-4.154 4.295-4.03l90.536 4.76c1.558.082 2.817 1.683 2.817 3.568v60.168c0 1.885-1.26 3.565-2.817 3.745l-90.536 10.423z" fill="#EEE" />
            <path d="m196.952 44.135-90.537-5.125c-3.478-.196-6.334 2.453-6.334 5.92v4.503l100.995 3.535v-3.592c0-2.766-1.836-5.112-4.124-5.241z" fill="#DFDFDF" />
            <path d="M111.27 44.828c0 1.11-.9 1.971-2.016 1.926-1.12-.046-2.031-.99-2.031-2.11 0-1.118.911-1.98 2.031-1.924 1.115.055 2.015.999 2.015 2.108zM118.217 45.142c0 1.094-.875 1.944-1.959 1.9-1.088-.045-1.973-.975-1.973-2.078 0-1.102.885-1.952 1.973-1.899 1.084.054 1.96.984 1.96 2.077zM124.97 45.448c0 1.078-.851 1.917-1.905 1.874-1.057-.044-1.918-.96-1.918-2.047 0-1.087.86-1.926 1.918-1.874 1.054.052 1.904.968 1.904 2.047z" fill="#EEE" />
            <path fill="#F8F8F8" d="m107.427 103.01 88.878-6.516V62.588l-88.878-.954z" />
            <path fill="#DFDFDF" d="m196.305 102.222-88.878 7.771v-3.157l88.878-7.201zM196.305 108.568l-88.878 9.169v-3.156l88.878-8.6zM158.099 56.1l-50.672-1.246v3.157l50.672.92zM191.825 56.928v2.616l4.48.081v-2.587zM145.102 93.885V67.642c0-1.684 1.313-2.79 2.443-2.067l19.545 12.514c1.181.757 1.181 3.112 0 3.944l-19.545 13.764c-1.13.796-2.443-.227-2.443-1.912z" />
          </g>
        </svg>
      </template>
    </template>
  </div>
</template>
